import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator'
import {
  Card,
  CardContent,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  List,
  Checkbox,
  Menu,
  MenuItem,
  Button,
  makeStyles,
} from '@material-ui/core'
import {
  AttachFile,
  Check,
  Email,
  ExpandMore,
  KeyboardArrowRight,
  MoreVert,
  PhoneIphone,
} from '@material-ui/icons'
import { styled } from '@material-ui/styles'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import { lightenColor } from 'containers/helper/ColorConverter'
import { convertDateTime, dateConvert } from 'containers/helper/formatDate'
import { useMenuOption } from 'containers/helper/hooks/useMenuOption'
import {
  BloodType,
  DietaryPreference,
  Status,
  useDataUpdateEApplicationInsertMutation,
  useDataUpdateEApplicationSummaryLazyQuery,
  useJobPortalInfoLazyQuery,
  useJobPortalInfoQuery,
  useTokenValidationLazyQuery,
} from 'generated/graphql'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import useInputStyles from '../CustomInputStyles'

export const DataUpdateEApplication = (props: any) => {
  // Define
  let history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const talentToken =
    window.location.pathname.split('/')?.pop() !== 'EApplication'
      ? window.location.pathname.split('/')?.pop()
      : null

  const {
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    getValues,
    formState,
    watch,
  } = useForm<any>({
    defaultValues: {
      DeclarationCheck: false,
    },
  })

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [portalToken, setPortalToken] = useState(state?.portalToken || null)
  const [TalentInfo, setTalentInfo] = useState(state?.TalentInfo || null)
  const [SubscriptionInfo, setSubscriptionInfo] = useState(
    state?.SubscriptionInfo || null
  )
  const [SectionCard, setSectionCard] = useState(state?.SectionCard || [])
  const [EApplicationListing, setEApplicationListing] = useState(null)
  const [EApplicationData, setEApplicationData] = useState(
    state?.EApplicationData || []
  )
  const [ListingData, setListingData] = useState(state?.ListingData || [])
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [invalidDialog, setInvalidDialog] = useState(false)
  const [saveDialog, setSaveDialog] = useState(false)
  const [submitDialog, setSubmitDialog] = useState(false)
  const [action, setAction] = useState('')
  const [PersonalInfo, setPersonalInfo] = useState(null)
  const [EducationInfo, setEducationInfo] = useState(null)
  const [DependentInfo, setDependentInfo] = useState(null)
  const [CertificateInfo, setCertificateInfo] = useState(null)
  const [EmpHistoryInfo, setEmpHistoryInfo] = useState(null)
  const [ReferenceInfo, setReferenceInfo] = useState(null)
  const [StatutoryInfo, setStatutoryInfo] = useState(null)
  const [HealthInfo, setHealthInfo] = useState(null)
  const [SupportingDocInfo, setSupportingDocInfo] = useState(null)
  const [isDraft, setIsDraft] = useState(false)
  const [isDisable, setIsDisable] = useState(false)
  const [TalentPoolID, setTalentPoolID] = useState(null)
  const [invalid, setInvalid] = useState('')
  const [JobPortal, setJobPortal] = useState(state?.JobPortal || null)
  const [JobPostingID, setJobPostingID] = useState(state?.JobPostingID || null)

  console.log('ListingData', ListingData)
  // console.log('SectionCard', SectionCard)
  // Query
  const [
    getTokenValidation,
    {
      data: { TokenValidation } = { TokenValidation: {} },
      loading: TokenValidationLoading,
    },
  ] = useTokenValidationLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.TokenValidation) {
        const {
          TalentInfo: talent,
          SubscriptionInfo: subscription,
          token,
          jobPostingID,
          invalidUrl,
        } = data?.TokenValidation

        if (talent && subscription) {
          setTalentInfo(talent)
          setTalentPoolID(talent?.rec_talent_pool_id)
          setSubscriptionInfo(subscription)

          if (!state?.portalToken) {
            setPortalToken(token)
          }
          if (!state?.JobPostingID) {
            setJobPostingID(jobPostingID)
          }
        } else if (
          (type === 'TalentInvitation' || type === 'DataUpdate') &&
          invalidUrl
        ) {
          setInvalid('url')
          setInvalidDialog(true)
        } else if (token && jobPostingID) {
          setPortalToken(token)
          setJobPostingID(jobPostingID)
          setInvalid('url')
          setInvalidDialog(true)
        } else {
          setPortalToken(token)
          setInvalid('posting')
          setInvalidDialog(true)
        }
      }
    },
  })

  const [
    getJobPortalInfo,
    {
      data: JobPortalInfo = { JobPortalInfo: null },
      loading: JobPortalInfoLoading,
    },
  ] = useJobPortalInfoLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.JobPortalInfo) {
        setJobPortal(data?.JobPortalInfo)
      }
    },
  })

  const [
    getDataUpdateEApplicationSummary,
    {
      data: { DataUpdateEApplicationSummary } = {
        DataUpdateEApplicationSummary: null,
      },
      refetch: DataUpdateEApplicationSummaryRefetch,
      loading: DataUpdateEApplicationSummaryLoading,
    },
  ] = useDataUpdateEApplicationSummaryLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.DataUpdateEApplicationSummary) {
        const {
          EApplicationList,
          SummaryData,
        } = data?.DataUpdateEApplicationSummary
        setEApplicationListing(EApplicationList)
        setEApplicationData(SummaryData)
        setListingData(prev => {
          if (SummaryData.length) {
            return SummaryData?.map(x => {
              if (x?.section_name !== 'Personal Info') {
                return { ...x, DeleteList: [] }
              }
              return x
            })
          }
          return prev
        })
      }
    },
  })

  const [
    DataUpdateEApplicationInsert,
  ] = useDataUpdateEApplicationInsertMutation({
    onError: error => {
      setIsDisable(false)
      snackBar(error?.message?.replace('GraphQL error:', ''), false)
    },
    onCompleted: data => {
      if (data?.DataUpdateEApplicationInsert) {
        if (isDraft) {
          getDataUpdateEApplicationSummary({
            variables: {
              TalentPoolID: data?.DataUpdateEApplicationInsert,
              SubscriptionAccountID: SubscriptionInfo?.SubscriptionAccountID,
            },
          })
          // setListingData([])
          // setSectionCard([])
          setTalentPoolID(data?.DataUpdateEApplicationInsert)
          // snackBar('Saved as Draft!', false)
          setSaveDialog(true)
          setIsDraft(false)
          setIsDisable(false)
        } else {
          snackBar('Submitted Successfully!', false)
          history.push(`/${type}/EApplicationSubmitted`, {
            ...state,
          })
        }
      } else {
        setIsDisable(false)
        if (isDraft) {
          snackBar('Fail to Save', false)
          setIsDraft(false)
        } else {
          snackBar('Submit Failed', false)
        }
      }
    },
  })

  // useEffect
  useEffect(() => {
    if (portalToken && !JobPortal) {
      getJobPortalInfo({
        variables: {
          PortalToken: portalToken,
        },
      })
    }
  }, [portalToken, JobPortal])

  useEffect(() => {
    if (talentToken && !ListingData?.length) {
      getTokenValidation({
        variables: {
          talentToken,
          type,
        },
      })
    }
  }, [talentToken, ListingData])

  useEffect(() => {
    if (SubscriptionInfo?.SubscriptionAccountID && ListingData?.length === 0) {
      // const storedTalentPoolID = sessionStorage.getItem('TalentPoolID')

      getDataUpdateEApplicationSummary({
        variables: {
          TalentPoolID: TalentPoolID,
          SubscriptionAccountID: SubscriptionInfo?.SubscriptionAccountID,
        },
      })
    }
  }, [SubscriptionInfo, ListingData, TalentPoolID])

  useEffect(() => {
    if (!ListingData?.length) {
      return
    }

    ListingData?.forEach(el => {
      switch (el?.section_name) {
        case 'Personal Info':
          setPersonalInfo(el)
          break

        case 'Education':
          setEducationInfo(el)
          break

        case 'Certificate':
          setCertificateInfo(el)
          break

        case 'Employment History':
          setEmpHistoryInfo(el)
          break

        case 'References':
          setReferenceInfo(el)
          break

        case 'Statutory Info':
          setStatutoryInfo(el)
          break

        case 'Health Info':
          setHealthInfo(el)
          break

        case 'Dependent':
          setDependentInfo(el)
          break

        case 'Supporting Documents':
          setSupportingDocInfo(el)
          break

        default:
          break
      }
    })

    if (SectionCard?.length) {
      const updatedCard = SectionCard?.map(el => {
        const matchingData = ListingData?.find(
          x => x?.section_name === el?.section_name
        )

        if (el?.section_name !== 'Declaration') {
          return {
            ...el,
            isError: false,
            DataList: matchingData?.DataList,
          }
        } else {
          return {
            ...el,
            isError: false,
          }
        }
      })
      setSectionCard(updatedCard)
    } else if (EApplicationListing?.length) {
      let initialCard = []

      EApplicationListing?.forEach(el => {
        let dataList = ListingData?.find(
          x => x?.section_name === el?.section_name
        )?.DataList

        if (el?.section_name === 'Personal Info') {
          const personalInfoData = ListingData?.find(
            x => x?.section_name === 'Personal Info'
          )?.DataList

          if (!personalInfoData?.Contact) {
            dataList = {
              ...personalInfoData,
              Contact: {
                FullName: TalentInfo?.name,
                Email: TalentInfo?.email,
              },
            }

            setPersonalInfo(prev => ({
              ...prev,
              DataList: dataList,
            }))

            setListingData(prev =>
              prev?.map(x => {
                if (x?.section_name === 'Personal Info') {
                  return {
                    ...x,
                    DataList: {
                      ...dataList,
                      Talent: personalInfoData?.Talent,
                    },
                  }
                }

                return x
              })
            )
          }
        }

        if (el?.section_name === 'Declaration') {
          const personalInfoData = ListingData?.find(
            x => x?.section_name === 'Personal Info'
          )?.DataList

          if (personalInfoData?.Talent?.declaration_dt) {
            dataList = {
              declaration_dt: null,
            }
          } else {
            dataList = null
          }
        }

        initialCard.push({
          ...el,
          isExpand: false,
          isError: false,
          DataList: dataList,
        })
      })

      setSectionCard(initialCard)
    }
  }, [EApplicationListing, ListingData])

  // Function
  const onSubmit = (draft: boolean) => {
    setIsDisable(true)
    const hasError = validateData(draft)

    if (!draft && hasError) {
      setIsDisable(false)
      return
    }

    if (ListingData) {
      const { Contact, Document, Talent, Employee } = PersonalInfo?.DataList
      const {
        ContactID = null,
        Age,
        DocumentFile,
        Description,
        ...contactRest
      } = Contact

      const contactInput = ContactID
        ? { ContactID, ...contactRest }
        : { ...contactRest }

      const { rec_talent_pool_id, declaration_dt, ...talentRest } = Talent || {}
      const talentInput = rec_talent_pool_id ? Talent : talentRest

      const {
        Health: healthInput,
        AttachmentToRemove,
        MedicalRecordInput,
        VaccinationRecordInput,
      } = HealthInfo?.DataList

      const attachmentToRemove = AttachmentToRemove?.map(
        ({ __typename, ...rest }) => rest
      )
      const medicalRecordInput = MedicalRecordInput
        ? [
            ...MedicalRecordInput.DataList.map(
              ({ __typename, Document, ...item }) => item
            ),
            ...MedicalRecordInput.DeleteList.map(
              ({ __typename, Document, ...item }) => item
            ),
          ]
        : []
      const vaccinationRecordInput = VaccinationRecordInput
        ? VaccinationRecordInput.map(({ __typename, ...item }) => item)
        : []

      const educationInput = EducationInfo
        ? [...EducationInfo?.DataList, ...EducationInfo?.DeleteList]
        : []
      const certificateInput = CertificateInfo
        ? [
            ...CertificateInfo.DataList.map(({ Document, ...item }) => item),
            ...CertificateInfo.DeleteList.map(({ Document, ...item }) => item),
          ]
        : []
      const empHistoryInput = EmpHistoryInfo
        ? [...EmpHistoryInfo?.DataList, ...EmpHistoryInfo?.DeleteList]
        : []
      const statutoryInput = StatutoryInfo?.DataList ?? null
      const dependentInput = DependentInfo
        ? [
            ...DependentInfo.DataList.map(({ IsDataImport, ...item }) => ({
              ...item,
              Contact: item.Contact
                ? (({ ParentID, ...rest }) => rest)(item.Contact)
                : {},
            })),
            ...DependentInfo.DeleteList.map(({ IsDataImport, ...item }) => ({
              ...item,
              Contact: item.Contact
                ? (({ ParentID, ...rest }) => rest)(item.Contact)
                : {},
            })),
          ]
        : []
      const referenceInput = ReferenceInfo
        ? [...ReferenceInfo?.DataList, ...ReferenceInfo?.DeleteList]
        : []
      const documentsInput = SupportingDocInfo
        ? [...SupportingDocInfo?.DataList, ...SupportingDocInfo?.DeleteList]
        : []

      if (draft) {
        setSectionCard(prev =>
          prev.map(x =>
            x?.section_name === 'Declaration' ? { ...x, DataList: null } : x
          )
        )
      }

      DataUpdateEApplicationInsert({
        variables: {
          contactInput: {
            ...contactInput,
            Status: draft ? Status.Draft : Status.Active,
          },
          documentInput: Document || null,
          talentInput: {
            ...talentInput,
            expected_salary: talentInput?.expected_salary
              ? Number(talentInput?.expected_salary)
              : null,
            declaration_dt:
              talentInput?.declaration_dt ||
              EApplicationData?.find(x => x?.section_name === 'Personal Info')
                ?.DataList?.Talent?.declaration_dt,
          },
          employeeInput: Employee || null,
          educationInput,
          certificateInput,
          empHistoryInput: empHistoryInput?.map(x => ({
            ...x,
            LastDrawnSalary: x?.LastDrawnSalary
              ? Number(x?.LastDrawnSalary)
              : x?.LastDrawnSalary,
          })),
          dependentInput,
          referenceInput,
          documentsInput,
          statutoryInput,
          healthInput: {
            ...healthInput,
            Height: healthInput?.Height ? Number(healthInput?.Height) : null,
            Weight: healthInput?.Weight ? Number(healthInput?.Weight) : null,
            BMI: healthInput?.BMI ? Number(healthInput?.BMI) : null,
            BloodType: Object.values(BloodType).find(
              i => i === healthInput?.BloodType?.replace(/ /g, '_')
            ),
            DietaryPreference: Object.values(DietaryPreference).find(
              i => i === healthInput?.Diet?.replace(/ /g, '_')
            ),
          },
          attachmentToRemove,
          vaccinationRecordInput,
          medicalRecordInput,
          SubscriptionAccountID: SubscriptionInfo?.SubscriptionAccountID,
          userID: SubscriptionInfo?.userID,
        },
      })
    }
  }

  const validateData = (isDraft: boolean) => {
    let hasError = false

    setSectionCard(prev =>
      prev?.map(x => ({
        ...x,
        isError: false,
      }))
    )

    const validatedSection = SectionCard?.map(x => {
      let isError = false

      if (!isDraft && x?.is_required && x?.min_record > 0) {
        if (
          x?.section_name === 'Personal Info' &&
          (!x?.DataList?.Contact?.Age ||
            !x?.DataList?.Contact?.Gender ||
            !x?.DataList?.Contact?.Marital ||
            !x?.DataList?.Talent?.preferred_location)
        ) {
          isError = true
          hasError = true
        } else if (
          x?.section_name === 'Declaration' &&
          !x?.DataList?.declaration_dt
        ) {
          isError = true
          hasError = true
        } else if (x?.DataList?.length < x?.min_record || !x?.DataList) {
          isError = true
          hasError = true
        }
      }

      return {
        ...x,
        isError,
      }
    })

    setSectionCard(validatedSection)

    return hasError
  }

  const handleDelete = () => {
    switch (action) {
      case 'Education':
        if (EducationInfo?.DataList) {
          const indexToDelete = EducationInfo?.DataList?.findIndex(
            x => x?.PersonnelEducationID === menu?.obj?.PersonnelEducationID
          )

          if (indexToDelete !== -1) {
            const existingData = EApplicationData?.find(
              x => x?.section_name === action
            )?.DataList?.find(
              x => x?.PersonnelEducationID === menu?.obj?.PersonnelEducationID
            )

            if (existingData) {
              EducationInfo?.DeleteList?.push({
                ...EducationInfo?.DataList[indexToDelete],
                Status: Status.Inactive,
              })
            }

            const updatedDataList = EducationInfo?.DataList?.filter(
              (_, index) => index !== indexToDelete
            )

            setEducationInfo(prev => ({
              ...prev,
              DataList: updatedDataList,
            }))

            setSectionCard(prev =>
              prev?.map(el =>
                el?.section_name === action
                  ? { ...el, DataList: updatedDataList }
                  : el
              )
            )

            handleClose()
            setDeleteDialog(false)
          }
        }
        break

      case 'Certificate':
        if (CertificateInfo?.DataList) {
          const indexToDelete = CertificateInfo?.DataList?.findIndex(
            x => x?.PersonnelCertificateID === menu?.obj?.PersonnelCertificateID
          )

          if (indexToDelete !== -1) {
            const existingData = EApplicationData?.find(
              x => x?.section_name === action
            )?.DataList?.find(
              x =>
                x?.PersonnelCertificateID === menu?.obj?.PersonnelCertificateID
            )

            if (existingData) {
              CertificateInfo?.DeleteList?.push({
                ...CertificateInfo?.DataList[indexToDelete],
                Status: Status.Inactive,
              })
            }

            const updatedDataList = CertificateInfo?.DataList?.filter(
              (_, index) => index !== indexToDelete
            )

            setCertificateInfo(prev => ({
              ...prev,
              DataList: updatedDataList,
            }))

            setListingData(prev =>
              prev?.map(el =>
                el?.section_name === action
                  ? { ...el, DataList: updatedDataList }
                  : el
              )
            )

            setSectionCard(prev =>
              prev?.map(el =>
                el?.section_name === action
                  ? { ...el, DataList: updatedDataList }
                  : el
              )
            )

            handleClose()
            setDeleteDialog(false)
          }
        }
        break

      case 'Employment History':
        if (EmpHistoryInfo?.DataList) {
          const indexToDelete = EmpHistoryInfo?.DataList?.findIndex(
            x => x?.PersonnelJobHistoryID === menu?.obj?.PersonnelJobHistoryID
          )

          if (indexToDelete !== -1) {
            const existingData = EApplicationData?.find(
              x => x?.section_name === action
            )?.DataList?.find(
              x => x?.PersonnelJobHistoryID === menu?.obj?.PersonnelJobHistoryID
            )

            if (existingData) {
              EmpHistoryInfo?.DeleteList?.push({
                ...EmpHistoryInfo?.DataList[indexToDelete],
                Status: Status.Inactive,
              })
            }

            const updatedDataList = EmpHistoryInfo?.DataList?.filter(
              (_, index) => index !== indexToDelete
            )

            setEmpHistoryInfo(prev => ({
              ...prev,
              DataList: updatedDataList,
            }))

            setListingData(prev =>
              prev?.map(el =>
                el?.section_name === action
                  ? { ...el, DataList: updatedDataList }
                  : el
              )
            )

            setSectionCard(prev =>
              prev?.map(el =>
                el?.section_name === action
                  ? { ...el, DataList: updatedDataList }
                  : el
              )
            )

            handleClose()
            setDeleteDialog(false)
          }
        }
        break

      case 'References':
        if (ReferenceInfo?.DataList) {
          const indexToDelete = ReferenceInfo?.DataList?.findIndex(
            x => x?.PersonnelReferenceID === menu?.obj?.PersonnelReferenceID
          )

          if (indexToDelete !== -1) {
            const existingData = EApplicationData?.find(
              x => x?.section_name === action
            )?.DataList?.find(
              x => x?.PersonnelReferenceID === menu?.obj?.PersonnelReferenceID
            )

            if (existingData) {
              ReferenceInfo?.DeleteList?.push({
                ...ReferenceInfo?.DataList[indexToDelete],
                Status: Status.Inactive,
              })
            }

            const updatedDataList = ReferenceInfo?.DataList?.filter(
              (_, index) => index !== indexToDelete
            )

            setReferenceInfo(prev => ({
              ...prev,
              DataList: updatedDataList,
            }))

            setListingData(prev =>
              prev?.map(el =>
                el?.section_name === action
                  ? { ...el, DataList: updatedDataList }
                  : el
              )
            )

            setSectionCard(prev =>
              prev?.map(el =>
                el?.section_name === action
                  ? { ...el, DataList: updatedDataList }
                  : el
              )
            )

            handleClose()
            setDeleteDialog(false)
          }
        }
        break

      case 'Dependent':
        console.log(DependentInfo?.DataList)
        if (DependentInfo?.DataList) {
          const indexToDelete = DependentInfo?.DataList?.findIndex(
            x => x?.EmployeeDependentsID === menu?.obj?.EmployeeDependentsID
          )
          console.log(indexToDelete)

          if (indexToDelete !== -1) {
            const existingData = EApplicationData?.find(
              x => x?.section_name === action
            )?.DataList?.find(
              x => x?.EmployeeDependentsID === menu?.obj?.EmployeeDependentsID
            )

            if (existingData) {
              DependentInfo?.DeleteList?.push({
                ...DependentInfo?.DataList[indexToDelete],
                Status: Status.Inactive,
              })
            }

            const updatedDataList = DependentInfo?.DataList?.filter(
              (_, index) => index !== indexToDelete
            )
            console.log(updatedDataList)

            setDependentInfo(prev => ({
              ...prev,
              DataList: updatedDataList,
            }))

            setListingData(prev =>
              prev?.map(el =>
                el?.section_name === action
                  ? { ...el, DataList: updatedDataList }
                  : el
              )
            )

            setSectionCard(prev =>
              prev?.map(el =>
                el?.section_name === action
                  ? { ...el, DataList: updatedDataList }
                  : el
              )
            )

            handleClose()
            setDeleteDialog(false)
          }
        }
        break

      case 'Supporting Documents':
        if (SupportingDocInfo?.DataList) {
          const indexToDelete = SupportingDocInfo?.DataList?.findIndex(
            x => x?.DocumentID === menu?.obj?.DocumentID
          )

          if (indexToDelete !== -1) {
            const existingData = EApplicationData?.find(
              x => x?.section_name === action
            )?.DataList?.find(x => x?.DocumentID === menu?.obj?.DocumentID)

            if (existingData) {
              SupportingDocInfo?.DeleteList?.push({
                DocumentID:
                  SupportingDocInfo?.DataList[indexToDelete]?.DocumentID,
                Status: Status.Inactive,
              })
            }

            const updatedDataList = SupportingDocInfo?.DataList?.filter(
              (_, index) => index !== indexToDelete
            )

            setSupportingDocInfo(prev => ({
              ...prev,
              DataList: updatedDataList,
            }))

            setListingData(prev =>
              prev?.map(el =>
                el?.section_name === action
                  ? { ...el, DataList: updatedDataList }
                  : el
              )
            )

            setSectionCard(prev =>
              prev?.map(el =>
                el?.section_name === action
                  ? { ...el, DataList: updatedDataList }
                  : el
              )
            )

            handleClose()
            setDeleteDialog(false)
          }
        }
        break
    }
  }

  const renderMenuItem = () => {
    switch (action) {
      case 'Education':
        return [
          <MenuItem
            className="drawer-dropdown"
            onClick={() =>
              history.push(`/${type}/EApplication/EducationForm`, {
                SectionCard,
                talentToken,
                TalentInfo,
                SubscriptionInfo,
                EducationID: menu?.obj?.PersonnelEducationID,
                mode: 'Edit',
                portalToken,
                ListingData,
                JobPostingID,
                JobPortal,
              })
            }
          >
            Edit
          </MenuItem>,

          <MenuItem
            className="drawer-dropdown"
            onClick={() => setDeleteDialog(true)}
          >
            Delete
          </MenuItem>,
        ]

      case 'Certificate':
        return [
          <MenuItem
            className="drawer-dropdown"
            onClick={() =>
              history.push(`/${type}/EApplication/CertificateForm`, {
                SectionCard,
                talentToken,
                TalentInfo,
                SubscriptionInfo,
                CertificateID: menu?.obj?.PersonnelCertificateID,
                mode: 'Edit',
                portalToken,
                ListingData,
                JobPostingID,
                JobPortal,
              })
            }
          >
            Edit
          </MenuItem>,

          <MenuItem
            className="drawer-dropdown"
            onClick={() => setDeleteDialog(true)}
          >
            Delete
          </MenuItem>,
        ]

      case 'Dependent':
        return [
          <MenuItem
            className="drawer-dropdown"
            onClick={() =>
              history.push(`/${type}/EApplication/DependentForm`, {
                SectionCard,
                talentToken,
                TalentInfo,
                SubscriptionInfo,
                DependentID: menu?.obj?.EmployeeDependentsID,
                mode: 'Edit',
                portalToken,
                ListingData,
                JobPostingID,
                JobPortal,
              })
            }
          >
            Edit
          </MenuItem>,

          <MenuItem
            className="drawer-dropdown"
            onClick={() => setDeleteDialog(true)}
          >
            Delete
          </MenuItem>,
        ]

      case 'Employment History':
        return [
          <MenuItem
            className="drawer-dropdown"
            onClick={() =>
              history.push(`/${type}/EApplication/EmploymentHistoryForm`, {
                SectionCard,
                talentToken,
                TalentInfo,
                SubscriptionInfo,
                EmpHistoryID: menu?.obj?.PersonnelJobHistoryID,
                mode: 'Edit',
                portalToken,
                ListingData,
                JobPostingID,
                JobPortal,
              })
            }
          >
            Edit
          </MenuItem>,

          <MenuItem
            className="drawer-dropdown"
            onClick={() => setDeleteDialog(true)}
          >
            Delete
          </MenuItem>,
        ]

      case 'References':
        return [
          <MenuItem
            className="drawer-dropdown"
            onClick={() =>
              history.push(`/${type}/EApplication/ReferencesForm`, {
                SectionCard,
                talentToken,
                TalentInfo,
                SubscriptionInfo,
                ReferenceID: menu?.obj?.PersonnelReferenceID,
                mode: 'Edit',
                portalToken,
                ListingData,
                JobPostingID,
                JobPortal,
              })
            }
          >
            Edit
          </MenuItem>,

          <MenuItem
            className="drawer-dropdown"
            onClick={() => setDeleteDialog(true)}
          >
            Delete
          </MenuItem>,
        ]

      case 'Supporting Documents':
        return [
          <MenuItem
            className="drawer-dropdown"
            onClick={() =>
              history.push(`/${type}/EApplication/SupportingDocumentsForm`, {
                SectionCard,
                talentToken,
                TalentInfo,
                SubscriptionInfo,
                SupportingDocID: menu?.obj?.DocumentID,
                mode: 'Edit',
                portalToken,
                ListingData,
                JobPostingID,
                JobPortal,
              })
            }
          >
            Edit
          </MenuItem>,

          <MenuItem
            className="drawer-dropdown"
            onClick={() => setDeleteDialog(true)}
          >
            Delete
          </MenuItem>,
        ]
    }
  }

  const handleAccordionChange = panel => (event, isExpanded) => {
    setSectionCard(prev =>
      prev?.map(obj => ({
        ...obj,
        isExpand: obj.section_name === panel ? isExpanded : obj.isExpand,
      }))
    )
  }

  const PersonalInfoCard = info => {
    return (
      <>
        <ListItem
          style={{
            marginBottom: 0,
            paddingLeft: '16px',
            border: info?.isError ? '2px solid #e53935' : 'none',
          }}
        >
          <ListItemText
            primary={
              <>
                <span className="flex-space desc">
                  <span className="smTitle">{info?.section_name}</span>
                </span>
                <span className="desc" style={{ color: '#FF9800' }}>
                  {info?.DataList?.Contact?.Nationality || ''}
                </span>
              </>
            }
            secondary={
              <>
                <span
                  className="desc"
                  style={{
                    color: info?.isError ? 'red' : 'black',
                  }}
                >
                  {info?.DataList?.Contact?.Age &&
                  info?.DataList?.Contact?.Gender &&
                  info?.DataList?.Contact?.Marital &&
                  info?.DataList?.Talent?.preferred_location ? (
                    <>
                      {info?.DataList?.Contact?.Age}
                      <TextSeparator />
                      {info?.DataList?.Contact?.Gender}
                      <TextSeparator />
                      {info?.DataList?.Contact?.Marital}
                      <TextSeparator />
                      {info?.DataList?.Talent?.preferred_location}
                    </>
                  ) : (
                    'Please fill up your personal details.'
                  )}
                </span>
              </>
            }
          />

          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() =>
                history.push(`/${type}/EApplication/PersonalInfoForm`, {
                  SectionCard,
                  talentToken,
                  TalentInfo,
                  SubscriptionInfo,
                  portalToken,
                  ListingData,
                  JobPostingID,
                  JobPortal,
                })
              }
            >
              <KeyboardArrowRight />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </>
    )
  }

  const EducationCard = info => {
    return (
      <>
        <Card>
          <ExpansionPanel
            onClick={e => e.stopPropagation()}
            expanded={info?.isExpand}
            onChange={handleAccordionChange(info?.section_name)}
            style={{
              border: info?.isError ? '2px solid #e53935' : 'none',
            }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <List
                className="core-list"
                style={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <ListItem
                  style={{
                    boxShadow: 'none',
                    padding: 0,
                    paddingRight: '10px',
                    margin: 0,
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <span className="flex-space desc">
                          <span className="smTitle">{info?.section_name}</span>
                        </span>
                        <span
                          className="desc"
                          style={{
                            color: '#FF9800',
                          }}
                        >
                          {info?.DataList?.length || 0}
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        {info?.isError && (
                          <div className="extra-row">
                            <div className="desc" style={{ color: 'red' }}>
                              Please add at least {info?.min_record} education
                              detail.
                            </div>
                          </div>
                        )}

                        {!info?.isExpand && !info?.isError && (
                          <>
                            {info?.DataList?.length > 0 ? (
                              <>
                                <div className="flex-space desc">
                                  {info?.DataList[0]?.InstitutionName}
                                </div>
                                <div className="desc">
                                  {dateConvert(info?.DataList[0]?.EndDate)}
                                </div>

                                <div className="extra-row">
                                  <span className="desc">
                                    {info?.DataList[0]?.EducationLevel}
                                    {' in '}
                                    {info?.DataList[0]?.Description}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <div className="extra-row">
                                <div className="desc">
                                  {info?.is_required
                                    ? `Please add at least ${info?.min_record} education detail.`
                                    : `Please add your education details.`}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                </ListItem>
              </List>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List className="core-list" style={{ width: '100%' }}>
                {info?.DataList?.length === 0 ? (
                  <div
                    className="smTitle"
                    style={{
                      textAlign: 'center',
                      padding: '12px 0',
                    }}
                  >
                    No Record found
                  </div>
                ) : (
                  info?.DataList?.map((el, index) => (
                    <ListItem key={el?.PersonnelEducationID}>
                      <ListItemText
                        primary={
                          <>
                            <div className="flex-space desc">
                              <span className="smTitle">
                                {el?.InstitutionName}
                              </span>
                            </div>
                          </>
                        }
                        secondary={
                          <>
                            <div className="extra-row">
                              <span className="flex-space desc">
                                {`${dateConvert(el?.StartDate)} - ${dateConvert(
                                  el?.EndDate
                                )}`}
                              </span>
                            </div>

                            <div className="extra-row">
                              <span className="flex-space desc">
                                {el?.EducationLevel}
                                <TextSeparator />
                                {el?.Description}
                              </span>
                            </div>
                          </>
                        }
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={e => {
                            setAction(info?.section_name)
                            handleClick(e, el?.PersonnelEducationID, index, el)
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                )}

                <Grid item xs={12}>
                  <div
                    style={{
                      paddingTop: '16px',
                      textAlign: 'center',
                    }}
                  >
                    <span
                      className="mdDesc"
                      style={{
                        textDecoration: 'underline',
                        color: '#FF9800',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        history.push(`/${type}/EApplication/EducationForm`, {
                          SectionCard,
                          talentToken,
                          TalentInfo,
                          SubscriptionInfo,
                          mode: 'New',
                          portalToken,
                          ListingData,
                          JobPostingID,
                          JobPortal,
                        })
                      }
                    >
                      Click to Add
                    </span>
                  </div>
                </Grid>
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Card>
      </>
    )
  }

  const DependentCard = info => {
    return (
      <>
        <Card>
          <ExpansionPanel
            onClick={e => e.stopPropagation()}
            expanded={info?.isExpand}
            onChange={handleAccordionChange(info?.section_name)}
            style={{
              border: info?.isError ? '2px solid #e53935' : 'none',
            }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <List
                className="core-list"
                style={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <ListItem
                  style={{
                    boxShadow: 'none',
                    padding: 0,
                    paddingRight: '10px',
                    margin: 0,
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <span className="flex-space desc">
                          <span className="smTitle">{info?.section_name}</span>
                        </span>
                        <span
                          className="desc"
                          style={{
                            color: '#FF9800',
                          }}
                        >
                          {info?.DataList?.length || 0}
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        {info?.isError && (
                          <div className="extra-row">
                            <div className="desc" style={{ color: 'red' }}>
                              Please add at least {info?.min_record} dependent
                              detail.
                            </div>
                          </div>
                        )}

                        {!info?.isExpand && !info?.isError && (
                          <>
                            {info?.DataList?.length > 0 ? (
                              <>
                                <div
                                  className="flex-space desc"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {info?.DataList[0]?.Contact?.FullName}
                                  <TextSeparator />
                                  <PhoneIphone
                                    style={{
                                      fontSize: '12px',
                                      color: 'grey',
                                    }}
                                  />
                                  <span style={{ textDecoration: 'underline' }}>
                                    {info?.DataList[0]?.Contact?.MobileNo ||
                                      'Not provided'}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <div className="extra-row">
                                <div className="desc">
                                  {info?.is_required
                                    ? `Please add at least ${info?.min_record} dependent detail.`
                                    : `Please add your dependent details.`}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                </ListItem>
              </List>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List className="core-list" style={{ width: '100%' }}>
                {info?.DataList?.length === 0 ? (
                  <div
                    className="smTitle"
                    style={{
                      textAlign: 'center',
                      padding: '12px 0',
                    }}
                  >
                    No Record found
                  </div>
                ) : (
                  info?.DataList?.map((el, index) => (
                    <ListItem key={el?.EmployeeDependentsID}>
                      <ListItemText
                        primary={
                          <>
                            <div className="flex-space desc">
                              <span className="smTitle">
                                {el?.Contact?.FullName}
                              </span>
                            </div>
                          </>
                        }
                        secondary={
                          <>
                            <div className="extra-row">
                              <span className="flex-space desc">
                                {el?.Relationship}
                              </span>
                            </div>

                            <div className="extra-row">
                              <div
                                className="flex-space desc"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <PhoneIphone
                                  style={{
                                    fontSize: '12px',
                                    color: 'grey',
                                  }}
                                />
                                &nbsp;
                                <span style={{ textDecoration: 'underline' }}>
                                  {el?.Contact?.MobileNo || 'Not provided'}
                                </span>
                              </div>
                              {el?.IsEmergencyContact && (
                                <div className="desc">Emergency Contact</div>
                              )}
                            </div>
                          </>
                        }
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={e => {
                            setAction(info?.section_name)
                            handleClick(e, el?.EmployeeDependentsID, index, el)
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                )}

                <Grid item xs={12}>
                  <div
                    style={{
                      paddingTop: '16px',
                      textAlign: 'center',
                    }}
                  >
                    <span
                      className="mdDesc"
                      style={{
                        textDecoration: 'underline',
                        color: '#FF9800',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        history.push(`/${type}/EApplication/DependentForm`, {
                          SectionCard,
                          talentToken,
                          TalentInfo,
                          SubscriptionInfo,
                          mode: 'New',
                          portalToken,
                          ListingData,
                          JobPostingID,
                          JobPortal,
                        })
                      }
                    >
                      Click to Add
                    </span>
                  </div>
                </Grid>
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Card>
      </>
    )
  }

  const CertificateCard = info => {
    return (
      <>
        <Card>
          <ExpansionPanel
            onClick={e => e.stopPropagation()}
            expanded={info?.isExpand}
            onChange={handleAccordionChange(info?.section_name)}
            style={{
              border: info?.isError ? '2px solid #e53935' : 'none',
            }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <List
                className="core-list"
                style={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <ListItem
                  style={{
                    boxShadow: 'none',
                    padding: 0,
                    paddingRight: '10px',
                    margin: 0,
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <div className="flex-space desc">
                          <span className="smTitle">{info?.section_name}</span>
                        </div>
                        <span
                          className="desc"
                          style={{
                            color: '#FF9800',
                          }}
                        >
                          {info?.DataList?.length || 0}
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        {info?.isError && (
                          <div className="extra-row">
                            <div className="desc" style={{ color: 'red' }}>
                              Please add at least {info?.min_record} certificate
                              detail.
                            </div>
                          </div>
                        )}

                        {!info?.isExpand && !info?.isError && (
                          <>
                            {info?.DataList?.length > 0 ? (
                              <>
                                <div className="extra-row">
                                  <span className="desc">
                                    {info?.DataList[0]?.Description}
                                  </span>
                                </div>

                                <div className="extra-row">
                                  <div className="flex-space desc">
                                    {new Date(
                                      info?.DataList[0]?.IssueDate
                                    ).getFullYear()}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="extra-row">
                                <div className="desc">
                                  {info?.is_required
                                    ? `Please add at least ${info?.min_record} certificate detail.`
                                    : `Please add your certificate details.`}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                </ListItem>
              </List>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List className="core-list" style={{ width: '100%' }}>
                {info?.DataList?.length === 0 ? (
                  <div
                    className="smTitle"
                    style={{
                      textAlign: 'center',
                      padding: '12px 0',
                    }}
                  >
                    No Record found
                  </div>
                ) : (
                  info?.DataList?.map((el, index) => (
                    <ListItem key={el?.PersonnelCertificateID}>
                      <ListItemText
                        primary={
                          <>
                            <div className="flex-space desc">
                              <span className="smTitle">{el?.Description}</span>
                            </div>
                          </>
                        }
                        secondary={
                          <>
                            <div className="extra-row">
                              <span className="flex-space desc">
                                {el?.ExpiryDate
                                  ? `${dateConvert(
                                      el?.IssueDate
                                    )} - ${dateConvert(el?.ExpiryDate)}`
                                  : dateConvert(el?.IssueDate)}
                              </span>
                            </div>

                            <div className="extra-row">
                              <span className="flex-space desc">
                                {el?.RegNo}
                              </span>
                            </div>
                          </>
                        }
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={e => {
                            setAction(info?.section_name)
                            handleClick(
                              e,
                              el?.PersonnelCertificateID,
                              index,
                              el
                            )
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                )}

                <Grid item xs={12}>
                  <div
                    style={{
                      paddingTop: '16px',
                      textAlign: 'center',
                    }}
                  >
                    <span
                      className="mdDesc"
                      style={{
                        textDecoration: 'underline',
                        color: '#FF9800',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        history.push(`/${type}/EApplication/CertificateForm`, {
                          SectionCard,
                          talentToken,
                          TalentInfo,
                          SubscriptionInfo,
                          mode: 'New',
                          portalToken,
                          ListingData,
                          JobPostingID,
                          JobPortal,
                        })
                      }
                    >
                      Click to Add
                    </span>
                  </div>
                </Grid>
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Card>
      </>
    )
  }

  const EmploymentHistoryCard = info => {
    return (
      <>
        <Card>
          <ExpansionPanel
            onClick={e => e.stopPropagation()}
            expanded={info?.isExpand}
            onChange={handleAccordionChange(info?.section_name)}
            style={{
              border: info?.isError ? '2px solid #e53935' : 'none',
            }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <List
                className="core-list"
                style={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <ListItem
                  style={{
                    boxShadow: 'none',
                    padding: 0,
                    paddingRight: '10px',
                    margin: 0,
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <div className="flex-space desc">
                          <span className="smTitle">{info?.section_name}</span>
                        </div>
                        <span
                          className="desc"
                          style={{
                            color: '#FF9800',
                          }}
                        >
                          {info?.DataList?.length || 0}
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        {info?.isError && (
                          <div className="extra-row">
                            <div className="desc" style={{ color: 'red' }}>
                              Please add at least {info?.min_record} employment
                              history.
                            </div>
                          </div>
                        )}
                        {!info?.isExpand && !info?.isError && (
                          <>
                            {info?.DataList?.length > 0 ? (
                              <>
                                <div className="extra-row">
                                  <div className="flex-space desc">
                                    {info?.DataList[0]?.CompanyName}
                                  </div>
                                </div>

                                <div className="extra-row">
                                  <div className="flex-space desc">
                                    {info?.DataList[0]?.JobPosition}
                                  </div>
                                  <div className="desc">
                                    {info?.DataList[0]?.IsCurrentJob
                                      ? `${dateConvert(
                                          info?.DataList[0]?.StartDate
                                        )} - Current`
                                      : `${dateConvert(
                                          info?.DataList[0]?.StartDate
                                        )} - ${dateConvert(
                                          info?.DataList[0]?.EndDate
                                        )}`}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="extra-row">
                                <div className="desc">
                                  {info?.is_required
                                    ? `Please add at least ${info?.min_record} employment history.`
                                    : `Please add your employment history.`}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                </ListItem>
              </List>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List className="core-list" style={{ width: '100%' }}>
                {info?.DataList?.length === 0 ? (
                  <div
                    className="smTitle"
                    style={{
                      textAlign: 'center',
                      padding: '12px 0',
                    }}
                  >
                    No Record found
                  </div>
                ) : (
                  info?.DataList?.map((el, index) => (
                    <ListItem key={el?.PersonnelJobHistoryID}>
                      <ListItemText
                        primary={
                          <>
                            <div className="flex-space desc">
                              <span className="smTitle">{el?.CompanyName}</span>
                            </div>
                          </>
                        }
                        secondary={
                          <>
                            <div className="extra-row">
                              <span className="flex-space desc">
                                {el?.IsCurrentJob
                                  ? `${dateConvert(el?.StartDate)} - Current`
                                  : `${dateConvert(
                                      el?.StartDate
                                    )} - ${dateConvert(el?.EndDate)}`}
                              </span>
                            </div>

                            <div className="extra-row">
                              <span className="flex-space desc">
                                {el?.JobPosition}
                              </span>
                            </div>
                          </>
                        }
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={e => {
                            setAction(info?.section_name)
                            handleClick(e, el?.PersonnelJobHistoryID, index, el)
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                )}

                <Grid item xs={12}>
                  <div
                    style={{
                      paddingTop: '16px',
                      textAlign: 'center',
                    }}
                  >
                    <span
                      className="mdDesc"
                      style={{
                        textDecoration: 'underline',
                        color: '#FF9800',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        history.push(
                          `/${type}/EApplication/EmploymentHistoryForm`,
                          {
                            SectionCard,
                            talentToken,
                            TalentInfo,
                            SubscriptionInfo,
                            mode: 'New',
                            portalToken,
                            ListingData,
                            JobPostingID,
                            JobPortal,
                          }
                        )
                      }
                    >
                      Click to Add
                    </span>
                  </div>
                </Grid>
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Card>
      </>
    )
  }

  const ReferencesCard = info => {
    return (
      <>
        <Card>
          <ExpansionPanel
            onClick={e => e.stopPropagation()}
            expanded={info?.isExpand}
            onChange={handleAccordionChange(info?.section_name)}
            style={{
              border: info?.isError ? '2px solid #e53935' : 'none',
            }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <List
                className="core-list"
                style={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <ListItem
                  style={{
                    boxShadow: 'none',
                    padding: 0,
                    paddingRight: '10px',
                    margin: 0,
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <div className="flex-space desc">
                          <span className="smTitle">{info?.section_name}</span>
                        </div>
                        <span
                          className="desc"
                          style={{
                            color: '#FF9800',
                          }}
                        >
                          {info?.DataList?.length || 0}
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        {info?.isError && (
                          <div className="extra-row">
                            <div className="desc" style={{ color: 'red' }}>
                              Please provide {info?.min_record} references.
                            </div>
                          </div>
                        )}
                        {!info?.isExpand && !info?.isError && (
                          <>
                            {info?.DataList?.length > 0 ? (
                              <>
                                <div
                                  className="flex-space desc"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {info?.DataList[0]?.Name}
                                  <TextSeparator />
                                  <PhoneIphone
                                    style={{
                                      fontSize: '12px',
                                      color: 'grey',
                                    }}
                                  />
                                  <span style={{ textDecoration: 'underline' }}>
                                    {info?.DataList[0]?.MobileNo}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <div className="extra-row">
                                <div className="desc">
                                  Please provide {info?.min_record} references.
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                </ListItem>
              </List>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List className="core-list" style={{ width: '100%' }}>
                {info?.DataList?.length === 0 ? (
                  <div
                    className="smTitle"
                    style={{
                      textAlign: 'center',
                      padding: '12px 0',
                    }}
                  >
                    No Record found
                  </div>
                ) : (
                  info?.DataList?.map((el, index) => (
                    <ListItem key={el?.PersonnelReferenceID}>
                      <ListItemText
                        primary={
                          <>
                            <div className="flex-space desc">
                              <span className="smTitle">{el?.Name}</span>
                            </div>
                          </>
                        }
                        secondary={
                          <>
                            <div className="extra-row">
                              <div
                                className="flex-space desc"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <PhoneIphone
                                  style={{
                                    fontSize: '12px',
                                    color: 'grey',
                                  }}
                                />
                                &nbsp;
                                <span style={{ textDecoration: 'underline' }}>
                                  {el?.MobileNo}
                                </span>
                              </div>
                            </div>

                            <div className="extra-row">
                              <span
                                className="flex-space desc"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Email
                                  style={{
                                    fontSize: '12px',
                                    color: 'grey',
                                  }}
                                />
                                &nbsp;
                                <span style={{ textDecoration: 'underline' }}>
                                  {el?.Email}
                                </span>
                              </span>
                            </div>
                          </>
                        }
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={e => {
                            setAction(info?.section_name)
                            handleClick(e, el?.PersonnelReferenceID, index, el)
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                )}

                <Grid item xs={12}>
                  <div
                    style={{
                      paddingTop: '16px',
                      textAlign: 'center',
                    }}
                  >
                    <span
                      className="mdDesc"
                      style={{
                        textDecoration: 'underline',
                        color: '#FF9800',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        history.push(`/${type}/EApplication/ReferencesForm`, {
                          SectionCard,
                          talentToken,
                          TalentInfo,
                          SubscriptionInfo,
                          mode: 'New',
                          portalToken,
                          ListingData,
                          JobPostingID,
                          JobPortal,
                        })
                      }
                    >
                      Click to Add
                    </span>
                  </div>
                </Grid>
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Card>
      </>
    )
  }

  const HealthInfoCard = info => {
    return (
      <>
        <ListItem
          style={{
            marginBottom: 0,
            paddingLeft: '16px',
            border: info?.isError ? '2px solid #e53935' : 'none',
          }}
        >
          <ListItemText
            primary={
              <>
                <span className="flex-space desc">
                  <span className="smTitle">{info?.section_name}</span>
                </span>
              </>
            }
            secondary={
              <>
                <span
                  className="desc"
                  style={{
                    color: info?.isError ? 'red' : 'black',
                  }}
                >
                  {info?.DataList?.Health ? (
                    <>
                      {`${info?.DataList?.Health?.Height} cm`}
                      <TextSeparator />
                      {`${info?.DataList?.Health?.Weight} kg`}
                    </>
                  ) : (
                    'Please update your health info.'
                  )}
                </span>
              </>
            }
          />

          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() =>
                history.push(`/${type}/EApplication/HealthInfoForm`, {
                  SectionCard,
                  talentToken,
                  TalentInfo,
                  SubscriptionInfo,
                  portalToken,
                  ListingData,
                  JobPostingID,
                  JobPortal,
                })
              }
            >
              <KeyboardArrowRight />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </>
    )
  }

  const StatutoryInfoCard = info => {
    return (
      <>
        <ListItem
          style={{
            marginBottom: 0,
            paddingLeft: '16px',
            border: info?.isError ? '2px solid #e53935' : 'none',
          }}
        >
          <ListItemText
            primary={
              <>
                <span className="flex-space desc">
                  <span className="smTitle">{info?.section_name}</span>
                </span>
              </>
            }
            secondary={
              <>
                <span
                  className="desc"
                  style={{
                    color: info?.isError ? 'red' : 'black',
                  }}
                >
                  {info?.DataList ? (
                    <>
                      {info?.DataList?.bank}
                      <TextSeparator />
                      {info?.DataList?.account_no}
                    </>
                  ) : (
                    'Please update your statutory info.'
                  )}
                </span>
              </>
            }
          />

          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() =>
                history.push(`/${type}/EApplication/StatutoryInfoForm`, {
                  SectionCard,
                  talentToken,
                  TalentInfo,
                  SubscriptionInfo,
                  portalToken,
                  ListingData,
                  JobPostingID,
                  JobPortal,
                })
              }
            >
              <KeyboardArrowRight />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </>
    )
  }

  const SupportingDocumentsCard = info => {
    return (
      <>
        <Card>
          <ExpansionPanel
            onClick={e => e.stopPropagation()}
            expanded={info?.isExpand}
            onChange={handleAccordionChange(info?.section_name)}
            style={{
              border: info?.isError ? '2px solid #e53935' : 'none',
            }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <List
                className="core-list"
                style={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <ListItem
                  style={{
                    boxShadow: 'none',
                    padding: 0,
                    paddingRight: '10px',
                    margin: 0,
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <div className="flex-space desc">
                          <span className="smTitle">{info?.section_name}</span>
                        </div>
                        <span
                          className="desc"
                          style={{
                            color: '#FF9800',
                          }}
                        >
                          {info?.DataList?.length || 0}
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        {info?.isError && (
                          <div className="extra-row">
                            <div className="desc" style={{ color: 'red' }}>
                              Please upload at least {info?.min_record}{' '}
                              supporting documents here.
                            </div>
                          </div>
                        )}
                        {!info?.isExpand && !info?.isError && (
                          <>
                            {info?.DataList?.length === 0 && (
                              <div className="extra-row">
                                <div className="desc">
                                  {info?.is_required
                                    ? `Please upload at least ${info?.min_record} supporting document here.`
                                    : `Upload your supporting documents here.`}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                </ListItem>
              </List>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List className="core-list" style={{ width: '100%' }}>
                {info?.DataList?.length === 0 ? (
                  <div
                    className="smTitle"
                    style={{
                      textAlign: 'center',
                      padding: '12px 0',
                    }}
                  >
                    No Record found
                  </div>
                ) : (
                  info?.DataList?.map((el, index) => (
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            <div className="flex-space desc">
                              {el?.DocumentName}
                            </div>
                          </>
                        }
                        secondary={
                          <>
                            <span
                              className="flex-space desc"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <AttachFile
                                color="primary"
                                style={{
                                  fontSize: '14px',
                                }}
                                onClick={() => {
                                  if (el?.Attachment) {
                                    const url = URL.createObjectURL(
                                      el?.Attachment
                                    )

                                    window.open(url, '_blank')

                                    setTimeout(
                                      () => URL.revokeObjectURL(url),
                                      100
                                    )
                                  } else if (el?.DocumentFile) {
                                    window.open(el?.DocumentFile, '_blank')
                                  }
                                }}
                              />
                              &nbsp;
                              {el?.Description}
                            </span>
                          </>
                        }
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={e => {
                            setAction(info?.section_name)
                            handleClick(e, el?.DocumentID, index, el)
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                )}

                <Grid item xs={12}>
                  <div
                    style={{
                      paddingTop: '16px',
                      textAlign: 'center',
                    }}
                  >
                    <span
                      className="mdDesc"
                      style={{
                        textDecoration: 'underline',
                        color: '#FF9800',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        history.push(
                          `/${type}/EApplication/SupportingDocumentsForm`,
                          {
                            SectionCard,
                            talentToken,
                            TalentInfo,
                            SubscriptionInfo,
                            mode: 'New',
                            portalToken,
                            ListingData,
                            JobPostingID,
                            JobPortal,
                          }
                        )
                      }
                    >
                      Click to Add
                    </span>
                  </div>
                </Grid>
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Card>
      </>
    )
  }

  const DeclarationCard = info => {
    return (
      <>
        <Card>
          <ExpansionPanel
            onClick={e => e.stopPropagation()}
            expanded={info?.isExpand}
            onChange={handleAccordionChange(info?.section_name)}
            style={{
              border: info?.isError ? '2px solid #e53935' : 'none',
            }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <List
                className="core-list"
                style={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <ListItem
                  style={{
                    boxShadow: 'none',
                    padding: 0,
                    paddingRight: '10px',
                    margin: 0,
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <div className="flex-space desc">
                          <span className="smTitle">{info?.section_name}</span>
                        </div>
                        {info?.DataList?.declaration_dt && (
                          <Check
                            color="primary"
                            style={{
                              color: '#FF9800',
                            }}
                          />
                        )}
                      </>
                    }
                    secondary={
                      <>
                        {!info?.isExpand &&
                          (!info?.DataList?.declaration_dt ? (
                            <div
                              className="desc"
                              style={{
                                color: info?.isError ? 'red' : 'black',
                              }}
                            >
                              Please read and acknowledge.
                            </div>
                          ) : (
                            <div className="desc">
                              Acknowledged on{' '}
                              {convertDateTime(info?.DataList?.declaration_dt)}
                            </div>
                          ))}
                      </>
                    }
                  />
                </ListItem>
              </List>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                {!info?.DataList?.declaration_dt && (
                  <Controller
                    control={control}
                    name="DeclarationCheck"
                    render={({ name, value, onChange }) => (
                      <Checkbox
                        color="primary"
                        name={name}
                        checked={value}
                        onChange={e => onChange(e.target.checked)}
                      />
                    )}
                  />
                )}
                <div
                  className="quill-content"
                  style={{
                    fontSize: 12,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: info?.content,
                  }}
                ></div>
              </div>

              {!info?.DataList?.declaration_dt && (
                <div
                  className="footer-btn"
                  style={{
                    display: 'flex',
                    marginTop: '20px',
                  }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    style={{
                      minWidth: '126px',
                      margin: '0 auto',
                      backgroundColor: watch('DeclarationCheck')
                        ? JobPortal?.theme_color
                        : undefined,
                    }}
                    variant="contained"
                    className={'single'}
                    onClick={() => {
                      setListingData(prev =>
                        prev?.map(x => {
                          if (x?.section_name === 'Personal Info') {
                            return {
                              ...x,
                              DataList: {
                                ...x?.DataList,
                                Talent: {
                                  ...x?.DataList?.Talent,
                                  declaration_dt: new Date(),
                                },
                              },
                            }
                          }
                          return x
                        })
                      )

                      setSectionCard(prev =>
                        prev?.map(x => {
                          if (x?.section_name === 'Declaration') {
                            return {
                              ...x,
                              DataList: {
                                declaration_dt: new Date(),
                              },
                            }
                          }
                          return x
                        })
                      )
                    }}
                    disabled={!watch('DeclarationCheck')}
                  >
                    Confirm
                  </Button>
                </div>
              )}
            </ExpansionPanelDetails>
            {info?.DataList?.declaration_dt && (
              <CardContents
                section={{
                  header: {
                    title: 'Acknowledgement',
                  },
                }}
                style={{
                  boxShadow: 'none',
                }}
              >
                <>
                  <div className="mdDesc">
                    Acknowledged on{' '}
                    {convertDateTime(info?.DataList?.declaration_dt)}
                  </div>
                </>
              </CardContents>
            )}
          </ExpansionPanel>
        </Card>
      </>
    )
  }

  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.goBack()
      }
    }, 2000)
  }

  return (
    <>
      {DataUpdateEApplicationSummaryLoading ||
      TokenValidationLoading ||
      JobPortalInfoLoading ||
      (!ListingData?.length && !invalid) ? (
        <Loading backdropColor={'#FF9800'} />
      ) : invalid ? (
        <CommonDialog
          fullWidth={true}
          open={invalidDialog}
          sections={{
            header: {
              children: (
                <ListItem className="remove-padding">
                  <ListItemText
                    primary={
                      <>
                        <span className="smTitle flex-space">
                          {invalid === 'url'
                            ? 'Invalid URL'
                            : 'Job Vacancy Unavailable'}
                        </span>
                      </>
                    }
                  />
                </ListItem>
              ),
            },
            body: () => (
              <>
                <div>
                  {invalid === 'url' && type === 'DataUpdate'
                    ? 'The URL you tried to access is expired. Kindly contact the Hiring Manager to request for a new URL.'
                    : invalid === 'url'
                    ? 'The URL you tried to access is expired. Click the button below and fill up your name and email to request for a new session.'
                    : 'The job vacancy is unavailable. Click the button below to redirect to the job portal.'}
                </div>
              </>
            ),
            footer: {
              actions: [
                {
                  displayText:
                    invalid === 'url' && type === 'DataUpdate'
                      ? 'Close'
                      : invalid === 'url'
                      ? 'Generate New URL'
                      : 'Go to Job Portal',
                  props: {
                    onClick: () => {
                      if (invalid === 'url' && type === 'DataUpdate') {
                        history.goBack()
                      } else if (invalid === 'url') {
                        history.push(`/JobPortal/JobPortalForm`, {
                          portalToken,
                          JobPostingID,
                        })

                        // sessionStorage.removeItem('TalentPoolID')
                      } else {
                        history.push(`/JobPortal/AvailableVacancyListing`, {
                          portalToken,
                        })
                      }
                    },
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
            },
          }}
        />
      ) : (
        <>
          <BasicHeader
            mainBtn="close"
            onClick={() => (portalToken ? setopenExitDialog(true) : null)}
            title="E-Application"
            primary={
              ListingData &&
              ListingData?.find(x => x?.section_name === 'Personal Info')
                ?.DataList?.Contact
                ? ListingData?.find(x => x?.section_name === 'Personal Info')
                    ?.DataList?.Contact?.FullName
                : TalentInfo?.name
            }
            secondary={
              ListingData &&
              ListingData?.find(x => x?.section_name === 'Personal Info')
                ?.DataList?.Contact
                ? ListingData?.find(x => x?.section_name === 'Personal Info')
                    ?.DataList?.Contact?.Email
                : TalentInfo?.email
            }
            themeColor={'#FF9800'}
          />

          <ContentWrapper externalMultiDynamicInfo footer>
            <List className="core-list">
              {SectionCard?.length > 0 &&
                SectionCard?.map(el => (
                  <>
                    <Card style={{ marginBottom: '4px' }}>
                      <CardContent
                        style={{
                          padding: 0,
                          background: 'transparent',
                        }}
                      >
                        {el?.section_name === 'Personal Info' &&
                          PersonalInfoCard(el)}
                        {el?.section_name === 'Education' && EducationCard(el)}
                        {el?.section_name === 'Certificate' &&
                          CertificateCard(el)}
                        {el?.section_name === 'Dependent' && DependentCard(el)}
                        {el?.section_name === 'Employment History' &&
                          EmploymentHistoryCard(el)}
                        {el?.section_name === 'References' &&
                          ReferencesCard(el)}
                        {el?.section_name === 'Health Info' &&
                          HealthInfoCard(el)}
                        {el?.section_name === 'Statutory Info' &&
                          StatutoryInfoCard(el)}
                        {el?.section_name === 'Supporting Documents' &&
                          SupportingDocumentsCard(el)}
                        {el?.section_name === 'Declaration' &&
                          DeclarationCard(el)}
                      </CardContent>
                    </Card>
                  </>
                ))}
            </List>
          </ContentWrapper>

          <SnackBarMsg
            open={openSnackBar}
            message={snackBarMessage}
            externalDisplay
          />

          <Footer
            externalDisplay
            options={
              ListingData?.find(x => x?.section_name === 'Personal Info')
                ?.DataList?.Contact?.Status === Status.Active
                ? [
                    {
                      onClick: () => {
                        handleSubmit(() => onSubmit(false))()
                      },
                      name: 'Submit',
                      color: 'primary',
                      disabled: isDisable,
                    },
                  ]
                : [
                    {
                      onClick: () => {
                        setIsDraft(true)
                        handleSubmit(() => onSubmit(true))()
                      },
                      name: 'Save',
                      color: 'primary',
                      variant: 'outlined',
                      disabled: isDisable,
                    },
                    {
                      onClick: () => {
                        const hasError = validateData(false)

                        if (hasError) {
                          return
                        }

                        setSubmitDialog(true)
                      },
                      name: 'Submit',
                      color: 'primary',
                      disabled: isDisable,
                    },
                  ]
            }
          />

          <Menu
            disableScrollLock={true}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClick={handleClose}
          >
            {renderMenuItem()}
          </Menu>

          <CommonDialog
            fullWidth={true}
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            sections={{
              header: {
                dynamic: (
                  <div className="">
                    <div className="dialog-dynamic-content">
                      <div className="session">
                        <div className="flex session">
                          <div className="title flex-space">{action}</div>

                          <span className="title">Delete</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
              body: () => (
                <>
                  <div className="mdDesc">Are you sure you want to delete?</div>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => {
                        setDeleteDialog(false)
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },

                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () => handleDelete(),
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ],
              },
            }}
          />

          <CommonDialog
            fullWidth={true}
            open={openExitDialog}
            onClose={() => setopenExitDialog(false)}
            sections={{
              header: {
                children: (
                  <ListItem className="remove-padding">
                    <ListItemText
                      primary={
                        <>
                          <span className="smTitle flex-space">
                            Exit Confirmation
                          </span>
                        </>
                      }
                    />
                  </ListItem>
                ),
              },
              body: () => (
                <>
                  <div>
                    Are you sure you want to exit? Your changes will not be
                    saved.
                  </div>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => setopenExitDialog(false),
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () => {
                        history.push(`/JobPortal/JobPortalForm`, {
                          portalToken,
                          JobPostingID,
                        })

                        // sessionStorage.removeItem('TalentPoolID')
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ],
              },
            }}
          />

          <CommonDialog
            fullWidth={true}
            open={saveDialog}
            onClose={() => setSaveDialog(false)}
            sections={{
              header: {
                dynamic: (
                  <div className="">
                    <div className="dialog-dynamic-content">
                      <div className="session">
                        <div className="flex session">
                          <div className="title flex-space">Saved Progress</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
              body: () => (
                <>
                  <div className="mdDesc" style={{ whiteSpace: 'initial' }}>
                    Your personal info update is saved to this point.
                  </div>
                  <div className="mdDesc" style={{ whiteSpace: 'initial' }}>
                    To continue personal info update, click on the same email
                    link again.
                  </div>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Close',
                    props: {
                      onClick: () => {
                        setSaveDialog(false)
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ],
              },
            }}
          />

          <CommonDialog
            fullWidth={true}
            open={submitDialog}
            onClose={() => setSubmitDialog(false)}
            sections={{
              header: {
                dynamic: (
                  <div className="">
                    <div className="dialog-dynamic-content">
                      <div className="session">
                        <div className="flex session">
                          <div className="title flex-space">
                            Submit Confirmation
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
              body: () => (
                <>
                  <div className="mdDesc" style={{ whiteSpace: 'initial' }}>
                    I declare that all the particular details and statements
                    given above are correct and true. I acknowledge that if any
                    particular, detailed and statement is found to be
                    deliberately misleading or false, it will make me liable for
                    dismissal.
                  </div>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => {
                        setSubmitDialog(false)
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },

                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () => handleSubmit(() => onSubmit(false))(),
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ],
              },
            }}
          />
        </>
      )}
    </>
  )
}
